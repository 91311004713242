.list-group-item:hover {
    background-color: #f0f0f0; /* Change to desired hover color */
  }
  /* body{
    background: #fff;
  } */

  .hide-on-mobile {
    @media (max-width: 600px) {
      display: none;
    }
  }